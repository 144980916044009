
import Vue, { PropType } from "vue"
import { AnalyticsEvent, Comment } from "@evercam/shared/types"
import TheCommentActionButton from "@evercam/shared/components/comments/TheCommentActionButton.vue"

export default Vue.extend({
  name: "TheCommentsListItem",
  components: {
    TheCommentActionButton,
  },
  props: {
    comment: {
      type: Object as PropType<Comment>,
      default: () => ({} as Comment),
    },
    selectedComment: {
      type: Object as PropType<Comment>,
      default: () => ({} as Comment),
    },
    isArchived: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
  },
  methods: {
    formattedTimestamp(timestamp) {
      return this.$moment
        .tz(timestamp, this.timezone)
        .format("MMM DD, YYYY [at] hh:mm A")
    },
    async openDeleteDialog(comment) {
      if (
        await this.$confirmDialog.open({
          title: this.$t("title.delete_comment"),
          message: this.$t("content.comments.delete"),
        })
      ) {
        this.$emit("delete-comment", comment)
        this.$root.$emit("analytics-event", {
          eventId: AnalyticsEvent.CommentsRemove,
        })
      } else {
        this.$root.$emit("analytics-event", {
          eventId: AnalyticsEvent.CommentsCancelRemove,
        })
      }
    },
    async openArchiveDialog(comment) {
      if (
        await this.$confirmDialog.open({
          title: this.$t("title.archive_comment"),
          message: this.$t("content.comments.archive"),
        })
      ) {
        this.$emit("archive-comment", comment)
        this.$root.$emit("analytics-event", {
          eventId: AnalyticsEvent.CommentsArchive,
        })
      } else {
        this.$root.$emit("analytics-event", {
          eventId: AnalyticsEvent.CommentsCancelArchive,
        })
      }
    },
  },
})
