
import Vue from "vue"

export default Vue.extend({
  name: "CommentPlacement",
  props: {
    isAnnotationActive: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Object,
      default: null,
    },
    mousePosition: {
      type: Object,
      default: () => ({
        x: 0,
        y: 0,
      }),
    },
    mouseDown: {
      type: Boolean,
      default: false,
    },
    offsetHeight: {
      type: Number,
      default: 0,
    },
    offsetWidth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      newPointPosition: {
        x: 0,
        y: 0,
      },
      comment: { content: "", timestamp: undefined },
      dialogRect: {},
    }
  },
  computed: {
    pointStyles() {
      return {
        position: "absolute",
        top: this.pointY - 15 + "px",
        left: this.pointX - 15 + "px",
        zIndex: "1",
      }
    },
    commentCardStyle() {
      let directionX = 20
      let directionY = 20
      if (this.pointX >= this.offsetWidth / 2) {
        directionX = -this.dialogRect.width - 20
      }

      if (this.pointY >= this.offsetHeight / 2) {
        directionY = -this.dialogRect.height - 20
      }

      return {
        position: "absolute",
        top: this.pointY + directionY + "px",
        left: this.pointX + directionX + "px",
        zIndex: "2",
      }
    },
    pointX() {
      const x = Math.floor(this.newPointPosition.x)

      return x > 0 ? x : 0
    },
    pointY() {
      return Math.floor(this.newPointPosition.y) > 0
        ? Math.floor(this.newPointPosition.y)
        : 0
    },
  },
  watch: {
    mouseDown(val) {
      if (val) {
        this.newPointPosition = {
          x: Math.floor(this.mousePosition.x),
          y: Math.floor(this.mousePosition.y),
        }
        if (this.isAnnotationActive) {
          this.$emit("point-selected", this.newPointPosition)
        }
      }
    },
    position(newPosition, oldPosition) {
      if (newPosition !== oldPosition) {
        this.newPointPosition = {
          x: Math.floor(newPosition.x),
          y: Math.floor(newPosition.y),
        }
        this.$nextTick(() => {
          this.dialogRect =
            this.$refs.commentCard?.$el?.getBoundingClientRect() || {}
        })
      }
    },
  },
})
