
import Vue from "vue"
export default Vue.extend({
  name: "TheCommentActionButton",
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "primary",
    },
  },
})
