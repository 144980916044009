
import Vue, { PropType } from "vue"
import { Comment } from "@evercam/shared/types"
import TheCommentsListItem from "@evercam/shared/components/comments/TheCommentsListItem.vue"

export default Vue.extend({
  name: "TheCommentsList",
  components: {
    TheCommentsListItem,
  },
  props: {
    comments: {
      type: Array as PropType<Comment[]>,
      default: () => [] as Array<Comment>,
    },
    archivedComments: {
      type: Array as PropType<Comment[]>,
      default: () => [] as Array<Comment>,
    },
    selectedComment: {
      type: Object as PropType<Comment>,
      default: () => ({} as Comment),
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
  },
  data() {
    return {
      selectedCommentId: null,
      commentTab: 0,
    }
  },
  watch: {
    selectedComment: {
      handler(comment) {
        if (comment) {
          this.selectedCommentId = comment.id
          this.scrollToSelectedComment(comment.id)
        }
      },
      immediate: true,
    },
  },
  methods: {
    scrollToSelectedComment(commentId) {
      const el = document.querySelector(`.selected-comment-${commentId}`)

      if (el) {
        el.scrollIntoView({ behavior: "smooth" })
      }
    },
  },
})
