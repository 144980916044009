
import Vue, { PropType } from "vue"
import { Comment } from "@evercam/shared/types"
import CommentTooltip from "@evercam/shared/components/comments/CommentTooltip"

export default Vue.extend({
  name: "CommentMarker",
  components: {
    CommentTooltip,
  },
  props: {
    offsetWidth: {
      type: Number,
      default: 0,
    },
    offsetHeight: {
      type: Number,
      default: 0,
    },
    comment: {
      type: Object as PropType<Comment>,
      default: () => ({} as Comment),
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hoveredMarker: false,
    }
  },
  computed: {
    markerStyle() {
      return {
        position: "absolute",
        top:
          this.comment.position2d?.coordinates[1] * this.offsetHeight -
          18 +
          "px",
        left:
          this.comment.position2d?.coordinates[0] * this.offsetWidth -
          18 +
          "px",
        zIndex: "1",
      }
    },
    markerTooltipStyle() {
      return {
        position: "absolute",
        top:
          this.comment.position2d.coordinates[1] * this.offsetHeight +
          20 +
          "px",
        left:
          this.comment.position2d.coordinates[0] * this.offsetWidth + 20 + "px",
        zIndex: "2",
      }
    },
  },
})
