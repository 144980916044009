
export default {
  name: "BimTransparencyArea",
  props: {
    imgDims: {
      type: Object,
      default: () => ({}),
    },
    sliderVal: {
      type: Number,
      default: 50,
    },
    shapes: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    getDenormalizedCoords([x, y]) {
      return [
        Math.round(x * this.imgDims.width),
        Math.round(y * this.imgDims.height),
      ]
    },
    getShapesPath() {
      if (this.shapes?.length <= 0) {
        //If no shapes should be transparent, render compare tool normally
        return `0 0,${(this.sliderVal / 100) * this.imgDims.width} 0,${
          (this.sliderVal / 100) * this.imgDims.width
        } ${this.imgDims.height},0 ${this.imgDims.height},0 0`
      }
      //We fecth all polygons to the left of the compare tool (needs to be shown)
      let combinedShapes = this.getTransparentAreas()
      //If the compare tool is to the left of all shapes, render normally
      if (combinedShapes.length <= 0) {
        return `0 0,${(this.sliderVal / 100) * this.imgDims.width} 0,${
          (this.sliderVal / 100) * this.imgDims.width
        } ${this.imgDims.height},0 ${this.imgDims.height},0 0`
      }
      //First point sews the end of each polygon with the first polygon to create an enclosed shape
      let [xn, yn] = this.getDenormalizedCoords(combinedShapes[0][0])
      //For each shape, create a polygon string to render using coordinates
      combinedShapes = combinedShapes.map((shape) => {
        let [x1, y1] = this.getDenormalizedCoords(shape[0])

        return (
          //Almost identical logic to measuring grid masks
          shape.reduce((acc, coord, index) => {
            const separator = index > 0 ? "," : ""
            let [x0, y0] = this.getDenormalizedCoords(acc)
            acc = index > 1 ? acc : `${x0} ${y0}`
            const [x, y] = this.getDenormalizedCoords(coord)

            return `${acc}${separator}${x} ${y}`
          }) + `,${x1} ${y1},${xn} ${yn},` //close each polygon with first points from first shape (enclosed)
        )
      })
      //stich all polygons together
      combinedShapes = combinedShapes.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      )

      //combine results with compare tool coordinates
      return `0 0,${(this.sliderVal / 100) * this.imgDims.width} 0,${
        (this.sliderVal / 100) * this.imgDims.width
      } ${this.imgDims.height},0 ${
        this.imgDims.height
      },0 0,${combinedShapes}0 0`
    },
    calculateIntersection(p1, p2, p3, p4) {
      // down part of intersection point formula
      var d1 = (p1[0] - p2[0]) * (p3[1] - p4[1]) // (x1 - x2) * (y3 - y4)
      var d2 = (p1[1] - p2[1]) * (p3[0] - p4[0]) // (y1 - y2) * (x3 - x4)
      var d = d1 - d2

      if (d == 0) {
        throw new Error("Number of intersection points is zero or infinity.")
      }

      // upper part of intersection point formula
      var u1 = p1[0] * p2[1] - p1[1] * p2[0] // (x1 * y2 - y1 * x2)
      var u4 = p3[0] * p4[1] - p3[1] * p4[0] // (x3 * y4 - y3 * x4)

      var u2x = p3[0] - p4[0] // (x3 - x4)
      var u3x = p1[0] - p2[0] // (x1 - x2)
      var u2y = p3[1] - p4[1] // (y3 - y4)
      var u3y = p1[1] - p2[1] // (y1 - y2)

      // intersection point formula

      var px = (u1 * u2x - u3x * u4) / d
      var py = (u1 * u2y - u3y * u4) / d

      var p = [px, py]

      return p
    },
    getTransparentAreas() {
      var newShapes = []
      var sliderXCoord = this.sliderVal / 100
      // for every shape...
      this.shapes.map((shape) => {
        // sort coordinates of a shape from the most left to the most right
        let sortedCoords = shape?.map((coord) => coord[0]).sort()
        // if the shape is on the left side of the slider then keep it
        if (sortedCoords[sortedCoords?.length - 1] < sliderXCoord) {
          newShapes.push(shape)
        }
        // if the slider intersects the shape
        else if (
          sortedCoords[sortedCoords?.length - 1] >= sliderXCoord &&
          sortedCoords[0] < sliderXCoord
        ) {
          var newShape = []
          // for each point of the shape (using for loop to access different indexes in the array)
          for (let i = 0; i < shape.length; i++) {
            // if the point is on the left side of the slider then keep it
            if (shape[i][0] < sliderXCoord) {
              newShape.push(shape[i])
            } else {
              var previousPoint =
                i == 0 ? shape[shape.length - 1] : shape[i - 1]
              var nextPoint = shape[(i + 1) % shape.length]
              var currentPoint = shape[i]
              // if the previous point is on the left side of the slider...
              if (previousPoint[0] < sliderXCoord) {
                // calculate the intersection between the vector of the two points and the vector of the slider
                newShape.push(
                  this.calculateIntersection(
                    previousPoint,
                    currentPoint,
                    [sliderXCoord, 0],
                    [sliderXCoord, 1]
                  )
                )
              }
              // if the next point is on the left side of the slider...
              if (nextPoint[0] < sliderXCoord) {
                // calculate the intersection between the vector of the two points and the vector of the slider
                newShape.push(
                  this.calculateIntersection(
                    nextPoint,
                    currentPoint,
                    [sliderXCoord, 0],
                    [sliderXCoord, 1]
                  )
                )
              }
            }
          }
          newShapes.push(newShape)
        }
      })

      return newShapes
    },
  },
}
