
import Vue, { PropType } from "vue"
import { Comment } from "@evercam/shared/types"

export default Vue.extend({
  name: "CommentTooltip",
  props: {
    item: {
      type: Object as PropType<Comment>,
      default: () => ({} as Comment),
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
  },
  computed: {
    formatedTimestamp() {
      return this.$moment
        .tz(this.item.timestamp, this.timezone)
        .format("MMM DD, YYYY [at] hh:mm A")
    },
  },
})
